import { Environment, EnvironmentVariables } from '@app-types/environment.types'

export const environment: EnvironmentVariables = {
    production: false,
    name: Environment.Staging,
    title: '🟠 De Eekhoorn',
    isCrawlAble: false,
    url: 'deeekhoorn.kubernetes.pwstaging.tech',
    api: {
        clientId: '965838ed-e9a1-431f-8f86-05f03246bf26',
        schema: 'https',
        hostname: 'api.deeekhoorn.kubernetes.pwstaging.tech',
        backoffice: 'backoffice.deeekhoorn.kubernetes.pwstaging.tech',
        oauthCallbackUrl: '/authentication/callback',
        oauthLoginUrl:  '/authorize',
        oauthTokenUrl: '/oauth/token',
        // Unused yet, but will be used in the future!
        acceptVersionRange: '>=0.0.1 <1.0.0',
    },
    subscriptionGraphql: {
        schema: 'wss',
        hostname: 'deeekhoorn.kubernetes.pwstaging.tech',
    },
    colijn: {
        url: 'https://eekhoorntest.ionecloud.nl/ione/',
        ajaxUrl: 'https://eekhoorntest.ionecloud.nl/ione/',
        upId: '134953',
        version: '252',
        assetPath: 'https://cdn.ione360.com/content43/',
        assetIndex: 'index-white.json',
        threeDAssetPath: 'https://cdn.ione360.com/content380',
    },
    algolia: {
        appId: 'JEU15VGAT9',
        apiKey: 'd56325bdb9feb408f5471f007add3377',
        default_index: 'deeekhoorn_en',
    },
    google: {
        maps: 'AIzaSyDvGcUrmDskBB_IVxU4vbOdPJVLK00JSQY',
        recaptcha: '6LcflXoiAAAAACWN4L_ICjcdaSfHlf6L7OPkC3e1',
        gtm: 'GTM-MMXKQJ',
    },
}
